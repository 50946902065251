import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import { useUser } from '@clerk/clerk-react';
import AvatarUpload from '../AvatarUpload';

import { InputField } from '../InputField';
import { accountDetailsResolver } from '../../../../validators/AuthValidators';
import useAlert from '../../../../CustomHooks/useAlert';
import { useForm } from 'react-hook-form';
import {
  axiosStripeInstance,
  useAxiosStripeInterceptors,
} from '../../../../api/apiInstances';
import useGenericMutation from '../../../../CustomHooks/useMutation';
import { Icons } from '../../../../helpers/icons';
import Alert from '../Alert';
import { UserProfile } from '../../../../common/types/userProfile';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  firstName: string;
  lastName: string;
  website?: string | null;
  etsyStoreUrl?: string | null;
}

const UserProfileForm = ({
  isProfilePage = false,
}: {
  isProfilePage?: boolean;
}) => {
  const { user } = useUser();
  useAxiosStripeInterceptors();

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [etsyStoreUrl, setEtsyStoreUrl] = useState('');
  const [website, setWebsite] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tikTok, setTikTok] = useState('');
  const [pinterest, setPinterest] = useState('');
  const [isChanged, setChanged] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [hasVisitedTrialPage, setHasVisitedTrialPage] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const {
    alert: avatarAlert,
    setAlert: setAvatarAlert,
    closeAlert,
  } = useAlert();

  const { mutateAsync: updateUserProfile } = useGenericMutation<any>(
    '/users/profile',
    'PUT',
    axiosStripeInstance
  );

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user?.id) {
        try {
          const response = await axiosStripeInstance.get<UserProfile>(
            `/users/${user.id}`
          );
          setUserProfile(response.data);
          setStreetAddress(response.data.street || '');
          setCity(response.data.city || '');
          setState(response.data.state || '');
          setZipCode(response.data.zip || '');
          setCountry(response.data.country || '');
          setEtsyStoreUrl(response.data.etsyStoreUrl ?? '');
          setWebsite(response.data.websiteUrl ?? '');
          setFacebook(response.data.facebook ?? '');
          setInstagram(response.data.instagram ?? '');
          setTikTok(response.data.tiktok ?? '');
          setPinterest(response.data.pinterest ?? '');
          setHasVisitedTrialPage(response.data.hasVisitedTrialPage);
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [user?.id]);

  useEffect(() => {
    if (userProfile) {
      const isProfileChanged =
        streetAddress !== userProfile.street ||
        city !== userProfile.city ||
        state !== userProfile.state ||
        zipCode !== userProfile.zip ||
        country !== userProfile.country ||
        etsyStoreUrl !== userProfile.etsyStoreUrl ||
        website !== userProfile.websiteUrl ||
        facebook !== userProfile.facebook ||
        instagram !== userProfile.instagram ||
        tikTok !== userProfile.tiktok ||
        pinterest !== userProfile.pinterest;

      const isBasicInfoChanged =
        selectedImage !== null ||
        (firstName !== user?.firstName && firstName !== '') ||
        (lastName !== user?.lastName && lastName !== '');

      setChanged(isProfileChanged || isBasicInfoChanged);
    } else {
      const isAnyFieldFilled =
        !!streetAddress ||
        !!city ||
        !!state ||
        !!zipCode ||
        !!country ||
        !!etsyStoreUrl ||
        !!website ||
        !!facebook ||
        !!instagram ||
        !!tikTok ||
        !!pinterest;

      setChanged(
        selectedImage !== null ||
          firstName !== user?.firstName ||
          lastName !== user?.lastName ||
          isAnyFieldFilled
      );
    }
  }, [
    selectedImage,
    firstName,
    lastName,
    streetAddress,
    city,
    state,
    zipCode,
    country,
    etsyStoreUrl,
    website,
    facebook,
    instagram,
    tikTok,
    pinterest,
    user,
    userProfile,
  ]);
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<FormValues>({
    resolver: accountDetailsResolver,
    mode: 'onBlur',
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      website: '',
      etsyStoreUrl: '',
    },
  });

  useEffect(() => {
    if (user) {
      setValue('firstName', user.firstName || '');
      setValue('lastName', user.lastName || '');
    }
  }, [user, setValue]);

  const handleUpdateAccount = async (data: FormValues) => {
    if (!isValid) {
      return;
    }
    setLoading(true);
    if (selectedImage && user) {
      try {
        await user?.setProfileImage({ file: selectedImage });
        setSelectedImage(null);
        setAvatarAlert({
          isSuccess: true,
          message: 'Profile updated successfully',
        });
      } catch (error) {
        console.error('Failed to upload profile image:', error);
        setAvatarAlert({
          isSuccess: false,
          message: 'Failed to upload profile image.',
        });
      }
    }
    if (
      data.firstName !== user?.firstName ||
      data.lastName !== user?.lastName
    ) {
      try {
        await user?.update({
          firstName: data.firstName,
          lastName: data.lastName,
        });
      } catch (error) {
        console.error('Failed to update user name:', error);
        setError('Failed to update user name.');
      }
    }

    const profileData = {
      street: streetAddress,
      city,
      state,
      zip: zipCode,
      country,
      etsyStoreUrl,
      websiteUrl: website,
      instagram,
      facebook,
      tiktok: tikTok,
      pinterest,
    };

    await updateUserProfile(profileData);

    setLoading(false);
    setChanged(false);
    if (isProfilePage) {
      if (!hasVisitedTrialPage) {
        navigate('/trial');
      } else {
        navigate('/home');
      }
    }
  };

  const handleInputChange =
    (name: keyof FormValues) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setValue(name, value);
      trigger(name);

      switch (name) {
        case 'firstName':
          setFirstName(value);
          break;
        case 'lastName':
          setLastName(value);
          break;
        case 'website':
          setWebsite(value);
          break;
        case 'etsyStoreUrl':
          setEtsyStoreUrl(value);
          break;
      }
    };
  return (
    <>
      {avatarAlert && <Alert {...avatarAlert} onClose={closeAlert} />}
      <div>
        <form onSubmit={handleSubmit(handleUpdateAccount)}>
          <div className="flex md:flex-row flex-col">
            <div className="pb-8 md:pb-10 md:basis-1/4">
              <h2 className="font-bold text-lg md:font-extrabold md:text-2xl mb-4 md:mb-8">
                User Information
              </h2>
              <AvatarUpload
                currentImage={user?.imageUrl || ''}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
              />
            </div>
            <div className="flex flex-col md:basis-3/4">
              <div className="flex justify-between gap-x-4 pb-4">
                <div className="flex flex-col flex-1">
                  <InputField
                    errorMsg={errors.firstName?.message}
                    register={register}
                    value={firstName}
                    onChange={handleInputChange('firstName')}
                    name="firstName"
                    placeholder="First Name"
                    inputClassName="!md:text-xl !text-sm pl-3"
                    required={true}
                    label="First Name"
                  />
                  {error && (
                    <p className="text-[#B3001F] text-sm md:text-lg">{error}</p>
                  )}
                </div>
                <div className="flex flex-col flex-1">
                  <InputField
                    errorMsg={errors.lastName?.message}
                    register={register}
                    value={lastName}
                    onChange={handleInputChange('lastName')}
                    name="lastName"
                    placeholder="Last Name"
                    inputClassName="!md:text-xl !text-sm pl-3"
                    required={true}
                    label="Last Name"
                  />
                  {error && (
                    <p className="text-[#B3001F] text-sm md:text-lg">{error}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between md:gap-x-4 gap-y-4 md:gap-y-0 pb-4">
                <div className="flex flex-col space-y-1 flex-1">
                  <div className="text-xs md:text-sm ">Email</div>
                  <InputField
                    value={user?.primaryEmailAddress?.emailAddress}
                    name="email"
                    placeholder="Email"
                    disabled={true}
                    Icon={Icons.Mail}
                    inputClassName="!md:text-xl !text-sm"
                  />
                </div>
                <div className="flex flex-col space-y-1 flex-1">
                  <div className="text-xs md:text-sm ">Street Address</div>
                  <InputField
                    value={streetAddress}
                    onChange={e => setStreetAddress(e.target.value)}
                    name="streetAddress"
                    placeholder="Street Address"
                    inputClassName="!md:text-xl !text-sm pl-3"
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-4 pb-4">
                <div className="flex flex-col space-y-1 flex-1">
                  <div className="text-xs md:text-sm ">City</div>
                  <InputField
                    value={city}
                    onChange={e => setCity(e.target.value)}
                    name="city"
                    placeholder="City"
                    inputClassName="!md:text-xl !text-sm pl-3"
                  />
                </div>
                <div className="flex flex-col space-y-1 flex-1">
                  <div className="text-xs md:text-sm ">State</div>
                  <InputField
                    value={state}
                    onChange={e => setState(e.target.value)}
                    name="state"
                    placeholder="State"
                    inputClassName="!md:text-xl !text-sm pl-3"
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-4 pb-4">
                <div className="flex flex-col space-y-1 flex-1">
                  <div className="text-xs md:text-sm ">Zip Code</div>
                  <InputField
                    value={zipCode}
                    onChange={e => {
                      setZipCode(e.target.value);
                    }}
                    type="number"
                    name="zipCode"
                    placeholder="Zip Code"
                    inputClassName="!md:text-xl !text-sm pl-3"
                  />
                </div>
                <div className="flex flex-col space-y-1 flex-1">
                  <div className="text-xs md:text-sm ">Country</div>
                  <InputField
                    value={country}
                    onChange={e => setCountry(e.target.value)}
                    name="country"
                    placeholder="Country"
                    inputClassName="!md:text-xl !text-sm pl-3"
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-4 pb-4">
                <div className="flex flex-col space-y-1 flex-1">
                  <div className="text-xs md:text-sm ">Etsy Store Url</div>
                  <InputField
                    errorMsg={errors.etsyStoreUrl?.message}
                    register={register}
                    value={etsyStoreUrl}
                    onChange={handleInputChange('etsyStoreUrl')}
                    name="etsyStoreUrl"
                    placeholder="Etsy Store Url"
                    inputClassName="!md:text-xl !text-sm pl-3"
                  />
                </div>
                <div className="flex flex-col space-y-1 flex-1">
                  <div className="text-xs md:text-sm ">Website</div>
                  <InputField
                    errorMsg={errors.website?.message}
                    register={register}
                    value={website}
                    onChange={handleInputChange('website')}
                    name="website"
                    placeholder="Website"
                    inputClassName="!md:text-xl !text-sm pl-3"
                  />
                </div>
              </div>
              <div className="flex md:flex-row flex-col justify-between gap-x-4 ">
                <div className="flex basis-1/2">
                  <div className="flex flex-1 justify-between gap-x-4 pb-4">
                    <div className="flex flex-col space-y-1 flex-1">
                      <div className="text-xs md:text-sm ">Facebook</div>
                      <InputField
                        value={facebook}
                        onChange={e => {
                          setFacebook(e.target.value);
                        }}
                        name="facebook"
                        placeholder="Facebook"
                        inputClassName="!md:text-xl !text-sm pl-3"
                      />
                    </div>
                    <div className="flex flex-col space-y-1 flex-1">
                      <div className="text-xs md:text-sm ">Instagram</div>
                      <InputField
                        value={instagram}
                        onChange={e => setInstagram(e.target.value)}
                        name="instagram"
                        placeholder="Instagram"
                        inputClassName="!md:text-xl !text-sm pl-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex basis-1/2">
                  <div className="flex flex-1 justify-between gap-x-4 pb-4">
                    <div className="flex flex-col space-y-1 flex-1">
                      <div className="text-xs md:text-sm ">TikTok</div>
                      <InputField
                        value={tikTok}
                        onChange={e => setTikTok(e.target.value)}
                        name="tikTok"
                        placeholder="TikTok"
                        inputClassName="!md:text-xl !text-sm pl-3"
                      />
                    </div>
                    <div className="flex flex-col space-y-1 flex-1">
                      <div className="text-xs md:text-sm ">Pinterest</div>
                      <InputField
                        value={pinterest}
                        onChange={e => setPinterest(e.target.value)}
                        name="pinterest"
                        placeholder="Pinterest"
                        inputClassName="!md:text-xl !text-sm pl-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-xs md:text-sm mb-4 bg-[#D7D7D7] py-4 px-6 lg:px-24 rounded-xl text-center">
                Please provide only{' '}
                <span className="font-bold">
                  business social media accounts
                </span>{' '}
                (e.g., Business Instagram, Business Facebook). Personal accounts
                should not be used, as these profiles will appear in your public
                business listings (e.g., Etsy descriptions).
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col md:justify-end justify-end w-100 mb-8 pb-8 border-b mt-5 md:gap-x-4 md:gap-y-0 gap-y-4">
            <Button
              color="primary"
              label={'Save'}
              className="px-[75px] py-3 rounded-lg font-bold text-lg text-white w-full md:w-auto "
              disabled={!isChanged || !isValid}
              type="submit"
              loading={isLoading}
            />
            {hasVisitedTrialPage && isProfilePage && (
              <Button
                label={'Skip'}
                onClick={() => navigate('/home')}
                className="px-[75px] py-3 rounded-lg font-bold text-lg text-white w-full md:w-auto"
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default UserProfileForm;
